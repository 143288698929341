const CONSTANTS = {
	ANALYTICS_MAPPING: {
		'landing-registration-trigger': 'sign_up',
		'landing-signin-trigger': 'login',
		fcp: 'fcp',
		'landing-page-view': 'ad_impression',
		'landing-play-demo': 'select_content',
		'landing-video-playing': 'select_content',
		'landing-watch-demo-play': 'select_content',
		'referral-link-landing': 'referral_link_landing',
	},
	AD_CONTENT: {
		podcast: {
			campaign: {
				defaultStanCampaign: {
					pageContent: {
						heroHeader: 'Your All·in·One Podcast Store',
						heroSubHeader: 'The easiest way for podcasters to grow, monetize, and engage their audience — all from your link-in-bio.',
						heroImage: '/images/home/ad-campaign/hero-podcast.png',
						replaceHero: true,
					},
				},
			},
		},
		dm: {
			campaign: {
				defaultStanCampaign: {
					pageContent: {
						heroHeader: 'Turn IG Comments Into Leads',
						heroSubHeader:
							'Turn Instagram comments into instant sales or sign-ups! Stan’s Auto-DMs automatically reply to comments with personalized messages, exclusive offers, or direct links — driving traffic and revenue while you focus on creating.',
						heroImage: '/images/home/ad-campaign/hero-dm.png',
						replaceHero: true,
					},
				},
			},
		},
		community: {
			campaign: {
				defaultStanCampaign: {
					pageContent: {
						heroHeader: 'All·in·One Community',
						heroSubHeader:
							'Stan is the easiest way to build and engage your community online. Create a space where fans connect, interact, and stay up-to-date — all from your link-in-bio.',
						heroImage: '/images/home/ad-campaign/hero-community.png',
						replaceHero: true,
					},
				},
			},
		},
		affiliate: {
			campaign: {
				defaultStanCampaign: {
					pageContent: {
						heroHeader: 'Amplify Your Sales with Affiliate Share',
						heroSubHeader:
							'Turn your fans into ambassadors with Stan’s Affiliate Share! Allow your super fans to promote and sell your products, extending your reach and driving more sales.',
						heroImage: '/images/home/ad-campaign/hero-affiliate.png',
						replaceHero: true,
					},
				},
			},
		},
		'healthwellness-gtm': {
			campaign: {
				'all-in-one': {
					pageContent: {
						heroHeader: 'Build your fitness empire with Stan',
						heroSubHeader:
							'The easiest platform for health and wellness creators to grow, monetize, and connect with your audience. Offer digital workout plans, schedule coaching sessions, and build a thriving fitness community -  all from your link-in-bio.',
						heroImage: '/images/home/ad-campaign/hero-healthwellness-all-in-one.png',
						replaceHero: true,
					},
				},
				ecourse: {
					pageContent: {
						heroHeader: 'The Easiest Way to Monetize Your Fitness Content',
						heroSubHeader:
							'From eCourses and masterclasses, to workshops and more, Stan’s all-in-one platform makes it easy to share your expertise and empower your clients to reach their goals.',
						heroImage: '/images/home/ad-campaign/hero-healthwellness-all-in-one.png',
						replaceHero: true,
						listItemSection: {
							header: 'Why eCourses on Stan Are Perfect for You',
							listItems: [
								{
									header: 'Boost Engagement & Motivation',
									body: 'Guide your community through transformative workouts or wellness journeys, step-by-step, on your terms!',
									iconSrc: '/images/home/ad-campaign/icons/muscle.png',
								},
								{
									header: 'Generate Extra Income',
									body: 'Charge for courses, set up memberships, or offer tiered access for extra value',
									iconSrc: '/images/home/ad-campaign/icons/moneybag.png',
								},
								{
									header: 'Flexible & Creator-Friendly',
									body: 'Easily design and launch your eCourse with tools made just for creators like you',
									iconSrc: '/images/home/ad-campaign/icons/art.png',
								},
							],
						},
					},
				},
				digitaldownloads: {
					pageContent: {
						heroHeader: 'Sell your Digital Workouts with Ease',
						heroSubHeader:
							'From workout routines, meal plans, wellness guides and more, Stan’s all-in-one platform makes it easy to ensure your clients crush their goals.',
						heroImage: '/images/home/ad-campaign/hero-healthwellness-all-in-one.png',
						replaceHero: true,
						listItemSection: {
							header: 'Why Start Selling Digital Products on Stan?',
							listItems: [
								{
									header: 'Share Practical Resources',
									body: 'Offer meal plans, smoothie recipes, or fitness routines that your followers can start using right away',
									iconSrc: '/images/home/ad-campaign/icons/muscle.png',
								},
								{
									header: 'Engage Followers with Unique Content',
									body: 'Charge for courses, set up memberships, or offer tiered access for extra value',
									iconSrc: '/images/home/ad-campaign/icons/books.png',
								},
								{
									header: 'Quick & Easy Setup',
									body: 'Create, upload, and sell with no hassle — focus on what you love! ',
									iconSrc: '/images/home/ad-campaign/icons/rocket.png',
								},
							],
						},
					},
				},
				community: {
					pageContent: {
						heroHeader: 'Invite, engage, and connect with your wellness tribe',
						heroSubHeader:
							'From workout routines, meal plans, wellness guides and more, Stan’s all-in-one platform makes it easy to ensure your clients crush their goals.',
						heroImage: '/images/home/ad-campaign/hero-healthwellness-all-in-one.png',
						replaceHero: true,
						listItemSection: {
							header: 'Why Your Followers Deserve a Community',
							listItems: [
								{
									header: 'Stronger Connections',
									body: 'Host a space for meaningful conversations that foster growth and deeper engagement.',
									iconSrc: '/images/home/ad-campaign/icons/muscle.png',
								},
								{
									header: 'Exclusive Content',
									body: 'Offer more than just content—create memberships, workshops, and one-on-one coaching.',
									iconSrc: '/images/home/ad-campaign/icons/gem.png',
								},
								{
									header: 'Effortless Community Building',
									body: 'With easy-to-use tools for managing your community, you can focus on nurturing a supportive environment.',
									iconSrc: '/images/home/ad-campaign/icons/seedling.png',
								},
							],
						},
					},
				},
			},
		},
	},
	GOOGLE_AD_CONVERSION_ID: 'AW-10854378575',
	GOOGLE_GA4_MEASUREMENT_ID: 'G-FPXVPVPGVK',
	GOOGLE_TAG_MANAGER_ID: 'GTM-W9MXN8NB',

	GOOGLE_AD_CONV_ACTION: {
		SIGN_UP: 'AW-10854378575/7wODCK-_u94ZEM_Y4rco',
	},
	FAKE_PROFILES: {
		justinwelsh: {
			full_name: 'Justin Welsh',
			pageContent: {
				heroHeader: 'Your All-in-One Solopreneur Store',
				heroSubHeader: 'The Saturday Solopreneur community gets their first month free when joining Stan. Start your business today.',
				referralComment:
					// eslint-disable-next-line
					"“I've tested a lot of online platforms. Stan blows me away. It's by far the easiest, fastest, and most affordable choice for anyone starting out as a solopreneur.”",
				headshot: '/images/home/v6/justin-welsh-headshot.png',
				heroImage: '/images/home/v6/justin-welsh-phone-preview.png',
				// eslint-disable-next-line
				stanXcreatorHeader: "Exclusive ONLY to Justin's Community!",
				stanXcreatorSubheader:
					// eslint-disable-next-line
					"Justin is the only person who has a free 30 days trial when joining Stan. This is the only time you'll get this offer.",
				stanXcreatorImage: '/images/home/v6/stanXjustin.png',
			},
		},
		patflynn: {
			full_name: 'Pat Flynn',
			pageContent: {
				heroHeader: 'Your All·in·One Smart Passive Income Store',
				heroSubHeader: 'The SPI audience gets their first month free when joining Stan. Start your business today.',
				referralComment:
					// eslint-disable-next-line
					"“When it comes to setting up your brand and offerings online, Stan is the most impressive tool I’ve come across in years. Speed, ease of use, and an intuitive experience – Stan Store is definitely the one you’d want to start with.”",
				headshot: '/images/home/v6/pat-flynn-headshot.png',
				heroImage: '/images/home/v6/pat-flynn-hero.png',
				// eslint-disable-next-line
				stanXcreatorHeader: "Exclusive Offer for Pat Flynn's Audience!",
				stanXcreatorSubheader:
					// eslint-disable-next-line
					"Pat's audience gets 30 days free. Claim this limited offer now.",
				stanXcreatorImage: '/images/home/v6/stanXpat.png',
			},
		},
		chasedimond: {
			full_name: 'Chase Dimond',
			pageContent: {
				heroHeader: 'Meet Your All·in·One Creator Store',
				heroSubHeader: 'Stan is the easiest way to make money online. All of your courses, digital products, and bookings are now hosted within your link-in-bio.',
				referralComment:
					// eslint-disable-next-line
					"“Stan truly gets what Creators need to build their business. I've used a lot of tools in my day, but now I use Stan. It's the only platform you need to get the job done. Hands down, best experience.”",
				headshot: '/images/home/v6/chase-headshot.png',
				heroImage: '/images/home/v6/chase-hero.png',
				// eslint-disable-next-line
				stanXcreatorHeader: "Exclusive Offer for Chase Dimond's Community!",
				stanXcreatorSubheader:
					// eslint-disable-next-line
					"Chase's community gets 30 days free. Claim this limited offer now.",
				stanXcreatorImage: '/images/home/v6/stanXchase.png',
			},
		},
	},
}

export default CONSTANTS
